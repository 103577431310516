.table-requests {
  th {
    text-transform: uppercase;
  }
  > .fa {
    .fa-extra-margin();
    &:before{
      margin-right: 1px;
    }
  }
  tr {
    &:hover {
      td {
        background-color: fade(@color-neutral-light, 20%);
      }
    }
  }
  .list-request-actions {
    margin: @grid-gutter-width/2 0 @grid-gutter-width/2 @grid-gutter-width*2;
    transition: 100ms opacity ease-in-out 20ms;
    li {
      padding: 0;
      margin-right: @grid-gutter-width/4;
    }
  }
  .requester {
    &:before {
      .fa('\f007');
      margin-right: @grid-gutter-width/4;
    }
  }
}

.request-type {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  margin-right: @grid-gutter-width;
  &.accepted {
    .fa {
      background-color: @color-success;
      color: @color-white;
    }
  }
  &.rejected {
    .fa {
      background-color: @color-danger;
      color: @color-white;
    }
  }
  &.pending {
    .fa {
      background: @color-neutral-light;
    }
  }
  .fa {
    text-align: center;
    flex-shrink: 0;
    width: @grid-gutter-width + 10px;
    height: @grid-gutter-width + 10px;
    line-height: @grid-gutter-width + 10px;
    border-radius: @grid-gutter-width;
    margin-right: @grid-gutter-width/2;
  } // &.organization {
  //   &:before {
  //     .fa('\f0f7');
  //   }
  // }
  // &.dataset {
  //   &:before {
  //     .fa('\f0f6');
  //   }
  // }
  // &:before {
  //   color: inherit;
  //   background: @color-neutral-light;
  //   text-decoration: none;
  //   border-radius: 50%;
  //   padding: @grid-gutter-width/4 @grid-gutter-width/2;
  //   margin-right: @grid-gutter-width/2;
  // }
}