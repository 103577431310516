.dataset-container {
  .page-container;

  .module-content {
    .entry {
      font-weight: bold;
      font-size: 1.2em;
    }
    .number { font-size: 1.5em; }
  }

  .resources {
    margin: @grid-gutter-width 0 @grid-gutter-width;
  }

  .resource-list {
    background: @color-white;
    margin: 0;
  }

  .resource-item {
    padding: @grid-gutter-width;
    border-top: 1px solid @color-neutral-light;
    display: flex;

    &:hover {
      background: none;
    }

    .info {
      margin-left: @unit/2;
      margin-top: .1rem;
      a {
        display: flex;
        color: @base-text-color;
        margin-bottom: .5rem;
        align-items: center;
      }
    }
  }

  .resource-actions {
    margin-left: auto;
  }

  .resource-main-actions {
    padding: 0 20px 20px;
    display: flex;

    a {
      margin-left: auto;
    }

    &.empty {
      padding: 30px 20px;
    }
  }

  .resource-list-actions {
    padding: @grid-gutter-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-container {
    margin-top: 1px;
  }

  .map {
    width: 100%;
    min-height: 400px;
  }
}

.metadata {
  .title-l {
    margin: @grid-gutter-width*2 @grid-gutter-width @unit;
  }
}

.metadata-list {
  background: @color-white;
  list-style: none;
  margin: 0;

  li {
    border-top: 1px solid @color-neutral-light;
    display: grid;
    padding: @grid-gutter-width/3 @grid-gutter-width;
    grid-template-columns: 30% 70%;
  }

  label, p {
    margin: 0;
  }

  label {
    &::after {
      content: none;
    }

    font-weight: 700;

    &.custom {
      font-weight: 300;
    }
  }
}
