.page-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .page-title {
    width: 100%;
    .title-xl;
  }

  .left {
    flex: 1;
    margin-right: @grid-gutter-width*3;
  }
  .right {
    flex: 3;
  }

  .left .box {
    padding: @unit/2 @unit;
    display: flex;

    .image {
      flex: 1;
      height: auto;
      padding-right: .4em;
      position: relative;
      left: -4px;
      mix-blend-mode: multiply;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .info {
      flex: 3;
    }
  }

  .module-content {
    padding: @unit 0;
    margin: 0;
  }

  @media (max-width: @screen-tablet) {
    display: block;

    .left,
    .right, 
    & > .page-title {
      margin: @grid-gutter-width;
    }

    .page-title {
      width: auto;
    }

    .nav-tabs,
    .tab-content {
      margin: 0 @grid-gutter-width*-1;
    }
  }
}

.image-header {
	position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10% 3% 3%;
  margin: 0 @grid-gutter-width*-1;
  color: @color-white;
}

.bg-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  background-color: @color-neutral-pale;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image.cover {

  position: relative;
  min-height: 180px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image.center {
  position: relative;
  text-align: center;

  img {
    width: 60%;
    height: 60%;
    max-height: 200px;
    object-fit: contain;
  }
}

.module-content.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &>div {
    flex: 1;
    margin: @grid-gutter-width 3%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  &>.flex2 {
    flex: 2;
  }
}

// ABOUT PAGE

.grid3-header {
  color: @base-text-color;
}

.grid-nigeria-logo {
  max-width: 300px;
  margin-bottom: 3%;
}

.grid-logo {
  max-width: 300px;
  margin-bottom: 2%;
}

.partner {
  border-top: 1px solid @color-primary-pale;
  margin-bottom: @grid-gutter-width*2;
  display: flex;

  img {
    margin: 2% auto;
    width: 70%;
    object-fit: contain;
  }
}

.grid3-countries {
  position: relative;
  display: flex;
  padding: 3% 10%;
  margin: 0 @grid-gutter-width*-1;

  img {
    width: 50%;
    max-height: 400px;
    object-fit: contain;
  }

  ul li {
    .heading-l;
    padding-top: @unit;
    color: @color-primary-pale;
  }
}

// SUPPORT

.support-headline {
  .headline-xxl;
  position: relative;
  i {
    position: absolute;
    top: -.5em;

  }
}

.support-number {
  .title-xl;
  display: flex;
  align-items: center;
  font-size: @font-size-xxl;
  background: fade(@color-primary, 30%);
  padding: 0 .4em;
  border-radius: .2em;

  i {
    font-size: @font-size-xl;
    margin-right: .4em;
  }
}

.support-info {
  .heading-l;
}

.support-cases {
  .teaser-heading {
    color: @base-text-color;
  }

  .support-case {
    padding-left: 2rem;
    position: relative;
  }

  .support-case-title {
    .text-m;
    display: inline-block;
    color: @base-text-color;
    padding: .2em 0;

    i {
      color: @color-action;
      font-size: @font-size-l;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .support-case-date {
    font-size: @font-size-s;
    color: @color-neutral-base;
  }
}

.faq {
  .module-content {
    column-count: 2;
    column-gap: 5%;

    &>div {
      padding: @grid-gutter-width 0;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
}



@media (max-width: @screen-tablet) {

  .module-content.flex {
    flex-direction: column;

    &>div {
      margin: @grid-gutter-width 0;
    }
  }

  .faq {
  .module-content {
    column-count: 1;
  }
}
}

@media (max-width: @screen-tablet) {

  .image-header,
  .grid3-countries {
    margin: 0;
  }
}
