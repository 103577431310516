.input-tag {
  margin-top: @grid-gutter-width/2;
}

.import-tags{
  overflow: hidden;
  margin: @grid-gutter-width/2 0;
  background-color: @color-neutral-pale;
  border: 1px solid @color-neutral-light;
  padding: @grid-gutter-width;
  border-radius: @base-border-radius;

  .fa-upload{
    margin-right: @grid-gutter-width/4;
  }

  input[type=file]{
    max-width: 100%;
    color: @color-primary;
    padding: @grid-gutter-width/4;
  }
}

.custom-tag {
  .checkbox{
    input,
    span{
      display: none;
    }
  }
  .control-custom {
    margin-top: @grid-gutter-width/2;
    margin-bottom: 0;
    label {
      margin-bottom: @grid-gutter-width/4;
    }
    input {
      width: (@grid-gutter-width*17) - 10px;
    }
  }
}
