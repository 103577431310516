

/**
* temp modal
*/
.t-modal {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: fade(@base-text-color, 90%);
  z-index: 999;
  flex-direction: column;
}

.t-modal-heading {
  .heading-xl;
}

label {
  font-weight: 400;
}

.t-close {
  position: absolute;
  top: @grid-gutter-width;
  right: @grid-gutter-width;
  cursor: pointer;
  font-size: @font-size-l;
  color: @color-action-dark;
}

.t-body {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-main {
  background: @color-white;
  border-radius: @base-border-radius;
  max-width: 50rem;
  width: 80vw;
  padding: @grid-gutter-width;
  display: flex;
  flex-direction: column;
  position: relative;
}

.t-row {
  margin-bottom: @grid-gutter-width;
  display: flex;
  padding: 0 @grid-gutter-width;
  justify-content: space-between;

  &>div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.t-row input,
.t-row textarea {
  width: 100%;
  resize: vertical;
  font-family: @base-font-family;
  background: @color-neutral-pale;
  border-radius: @base-border-radius;
  box-shadow: 1px 1px 2px 0 fade(@color-neutral-dark, 20%) inset;
  border: 0;
}
