input[type=radio],
input[type=checkbox]{
  top: 3px;
}

select,
input {
  font-family: inherit;
}

textarea, 
input[type="text"], 
input[type="password"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="date"], 
input[type="month"], 
input[type="time"], 
input[type="week"], 
input[type="number"], 
input[type="email"], 
input[type="url"], 
input[type="search"], 
input[type="tel"], 
input[type="color"], 
.uneditable-input {

  height: 34px;
  padding: .4em .8em;
}

textarea {
	height: auto;
}

.input-append .add-on, .input-prepend .add-on {
  height: 34px;
}
