.btn {
  text-shadow: none;
  box-shadow: none;
  background: @color-action;
  border: 0;
  border-color: transparent;
  border-radius: @base-border-radius;
  padding: .2em .4em;
  letter-spacing: .03em;
  color: @color-white;
  transition: box-shadow .2s, background .2s;
  box-shadow: 0 0 0 0 @color-action-dark, 0 0 0 20px @color-action inset;

  &:hover,
  &:focus {
    background: @color-action-dark;
    color: @color-white;
    box-shadow: 0 0 0 1px @color-action-dark, 0 0 0 0px @color-action inset;
    transition: box-shadow .2s;
  }

  .fa {
    .fa-extra-margin;
  }
}

.btn-large {
  font-size: @font-size-l;
  padding: .7em 1.4em;
}

button {
  font-family: inherit;
  outline: none;
}

.btn-primary {
  background: @color-action;
  padding: .5em 1.2em;
  box-shadow: 0 0 0 0 @color-action-dark, 1px 1px 1px fade(@color-neutral-dark, 30%), 0 0 0 30px @color-action inset;

  &:hover,
  &:focus {
    background: @color-action-dark;
    box-shadow: 0 0 0 1px @color-action-dark, 1px 1px 1px 1px fade(@color-neutral-dark, 40%), 0 0 0 0px @color-action inset;
    transition: box-shadow .2s;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    background: @color-neutral-base;
    box-shadow: none;
  }
}

.btn-default {
  background: @color-primary-pale;
  box-shadow: 0 0 0 0 @color-neutral-base, 0 0 0 20px @color-primary-pale inset;
  padding: .5em 1.2em;

  &:hover,
  &:focus {
    background: darken(@color-primary-pale, 10);
    box-shadow: 0 0 0 1px darken(@color-primary-pale, 10), 0 0 0 0px @color-primary-pale inset;
  }
}

.list-buttons {
  li {
    margin-bottom: @grid-gutter-width - 5px;
  }
}
