@sector-width: 20vw;
@drawer-width: 30%;
@transition-duration: .4s;


.map-search-content {
  padding: 0;
  border-bottom: 20px solid @color-primary-pale;
}

.map-search {
  display: flex;
  flex-direction: column;
  height: 100vh;

  #nigeria_map {
    position: absolute;
    top:0;
    bottom: 0;
    width: 100%;
  }
}

.map-search_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  top: -20px;
  z-index:2;
  flex: 0 0 auto;
  background: @color-primary-pale;
  padding-top: @grid-gutter-width;
  padding-bottom: 0;
  color: @color-white;

  .dataset-count {
    font-weight: 300;
    margin-right: @grid-gutter-width;
  }
}

.map-search_tabs {
  display: flex;
  width: @sector-width;
  min-width: 280px;

  .tab {
    .title-l;
    padding: @grid-gutter-width/2;
    flex: 1;
    text-align: center;
    background: fade(@color-white, 20%);
    border-radius: 2px 2px 0 0;
    margin-right: 1px;
    cursor: pointer;

    i {
      margin-right: .5em;
    }

    &.active, 
    &:hover {
      background: @color-white;
      color: @base-text-color;
    }
  }
}

.map-search_filters {
  display: flex;
  justify-content: flex-end;
  padding: @grid-gutter-width/2 @grid-gutter-width @grid-gutter-width/4;

  .filter {
    font-size: @font-size-m;
    color: @color-primary-dark;
    border-left: 1px solid fade(@color-primary-pale, 30%);
    padding: 0 0 0 @unit*1.5;
  }
}

.map-search_container {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 6px;
  z-index: 0;
}

.map-search_sectors {
  display: flex;
  flex-direction: column;
  width: @sector-width;
  min-width: 280px;

  .top-bar {
    .select-label; 
    flex: 0 0 auto;
    min-height: 40px;
    background: @color-neutral-pale;
    margin: 6px;
    padding: .9rem .5rem 0;
  }

  .sectors {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 0 6px;
  }

  ul {
    margin: 0;
    padding: 0 6px;
    list-style: none;
  }

  .sector {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: @grid-gutter-width/4 @grid-gutter-width/1.5;
    cursor: pointer;
    transition: opacity .2s, padding .1s;

    .image {
      width: 24%;
      height: auto;
      padding-right: .4em;
      position: relative;
      left: -4px;
      transition: width .1s;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .info {
      width: 76%;
    }

    .heading {
      .title-m;
      margin: .2em 0 .4em 0;
    }

    .badge-number {
      position: absolute;
      top: 25%;
      right: 6px;
      transition: top .1s;
    }

    .resources {
      width: 100%;
      padding: .5em 0 0 1em;
      border-top: 1px solid fade(@color-primary-pale, 30%);
      margin-top: .5em;
      max-height: 300px;
      transition: max-height .2s;
      overflow: hidden;

      li {
        padding: .1em 0;
      }
    }
  }

  .sector.selected {
    border-radius: 2px;
    background: @color-neutral-pale;
    box-shadow: 1px 1px 2px fade(@color-neutral-dark, 25%);
    margin-bottom: 6px;
    padding: @grid-gutter-width/2 @grid-gutter-width/1.5;

    .badge-number {
      top: 6px;
      background: @color-action;
    }
  }

  .sector:not(.selected) {
    &+.sector:not(.selected) {
      border-top: 1px solid fade(@color-primary-pale, 60%);
    }
    
    opacity: .4;
    align-items: center;

    p {
      display: none;
    }

    .resources {
      max-height: 0;
      padding-top: 0;
      border-top: 0;
      margin-top: 0;
    }

    .image {
      width: 15%;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.map-search_results {
  display: flex;
  flex-direction: column;
  width: 100vw - @sector-width;

  .top-bar {
    margin: 6px 0;
    display: flex;
    min-height: 40px;
  }

  .select-location {
    background: @color-neutral-pale;
    padding: .5rem .5rem .2rem;
    width: 100% - @drawer-width;
    align-items: flex-start;

    .filter-label {
      .select-label; 
      padding-top: .4rem;
      font-weight: 700;
    }

    &:hover {
      background: darken(@color-neutral-pale, 3%);
    }
  }

  .search-form {
    padding: 0;
    border: 0;
    margin: 0;
    width: @drawer-width;
  }

  .search-form .search-input {
    background: @color-neutral-pale;
    margin: 0 6px;
    height: 40px;

    input {
      background: fade(@color-action, 20%);
      border-radius: 0;
      box-shadow: none;
    }

    input[value=""] {
      background: transparent;
    }

    &:hover {
      background: darken(@color-neutral-pale, 3%);
    }
  }

  .results {
    position: relative;
    display: flex;
    box-shadow: none;
    outline: none;
  }

  .map-container {
    position: relative;
    width: 100%;
    z-index: 0;
    transition: width @transition-duration;
  }
}

.datasets-list {
  list-style: none;
  margin: 0;
  padding: 3vw;
  position: relative;

  .list-header {
    display: flex;
    width: 100%;
    align-items: baseline;
  }

  .list-header-dataset {
    flex: 2;
    margin-right: @grid-gutter-width;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .list-header-resources {
    flex: 1;
    align-items: baseline;
  }

  .list-title {
    .heading-s;
    margin: 0;
    padding: .2em 0;
  }

  .dataset {
    display: flex;
    width: 100%;

    .dataset-info {
      flex: 2;
      border-top: 1px solid fade(@color-primary-pale, 30%);
      margin-right: @grid-gutter-width;
      padding: 1rem 0;
    }

    .resources {
      flex: 1;
      border-top: 1px solid fade(@color-primary-pale, 30%);
      list-style: none;
      margin: 0;
      padding: 1rem 0;

      li {
        padding: .1em 0;
      }
    }

    .title {
      .title-l;
      color: @base-text-color;
      display: inline-block;
      margin-bottom: .6rem;
    }
  }

  .loading-container {
    border-top: 1px solid fade(@color-primary-pale, 30%);
  }
}

.no-results {
  border-top: 1px solid fade(@color-primary-pale, 30%);
  width: 100%;
  height: 50vh;
  padding-top:20vh;
  text-align: center;
  font-size: @font-size-l;
  line-height: 1.3em;
  color: @color-neutral-base;
  background: linear-gradient(@color-neutral-pale, @color-white);

  .em {
    color: @color-primary;
  }
}

.datasets-drawer {
  background: @color-neutral-pale;
  box-shadow: 1px 0px 2px fade(@color-neutral-dark, 30%) inset;
  width: 6px;
  z-index: 3;
  position: relative;
  transition: width @transition-duration;

  .download-section {
    display: none;
  }
}

.drawer-toggle {
  position: absolute;
  left: -30px;
  width: 32px;
  height: 60px;
  padding: 1em .6em;
  box-shadow: 1px 1px 1px fade(@color-neutral-dark, 30%) inset;
  border-radius: 2px 0 0 2px;
  top: 40%;
  z-index: 3;
  background: @color-neutral-pale;
  font-size: 20px;
  color: @color-action;
}

.datasets-list-small {
  
  position: absolute;
  top:0;
  bottom: 30px;
  width: 100%;
  padding: 6px;
  overflow-y: auto;
  display: none;

  .list-title {
    .heading-s;
    margin: 0;
    padding: @grid-gutter-width/1.5;
    padding-bottom: 0;
  }

  ul {
    padding: @grid-gutter-width/1.5;
    margin: 0;
    list-style: none;
  }

  .dataset {
    border-top: 1px solid fade(@color-primary-pale, 30%);
    padding: .4em 0;
  }

  .resources {
    padding: .2em 0;

    li {
      padding: .1em 0 .1em 1em;
    }
  }

  .dataset.collapsed {
    .resources, 
    i {
      display: none;
    }
  }

  .dataset.selected {
    .title {
      font-weight: 700;
      color: @base-text-color;
    }
  }

  .title {
    color: @color-neutral-base;
    font-size: @font-size-m;
    font-weight: 400;
    padding: 0;
    margin: 0;

    i {
      color: @color-action;
      float: right;
      transform: rotate(45deg);
    }
  }

  .no-results {
    padding-top:10vh;
    background: none;
  }

  .loading-container {
    border-top: 1px solid fade(@color-primary-pale, 30%);
  }
}

.download-section {
  position: absolute;
  margin: 0 6px;
  left:0;
  right: 0;
  bottom: 1px;
  top: 100%;
  min-width: 200px;
  background: @color-neutral-pale;
  border-radius: @base-border-radius @base-border-radius 0 0;
  box-shadow: 1px 0 1px fade(@color-neutral-dark, 30%);
  display: flex;
  flex-direction: column;
  transition: top .4s;

  &:not(.collapsed) {
    top: 46px;

    .download-toggle .download-toggle-icon {
      border: 0;
      border-bottom: 3px solid @color-white;
    }
  }

  &.collapsed {
    .download-button {
      display: none;
    }
    .download-settings {
      margin: 0;
    }
  }

  .download-toggle {
    border-radius: @base-border-radius @base-border-radius 0 0;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -40px;
    height: 40px;
    padding-left: @grid-gutter-width/1.5;
    padding-right: @grid-gutter-width/1.5;

    .download-toggle-icon {
      width: 1rem;
      height: 1rem;
      border-top: 3px solid @color-white;
    }
  }

  .download-settings {
    overflow-y: auto;
    margin-bottom: 40px;

    .settings-section {
      list-style: none;
      margin: 0 @grid-gutter-width/1.5;
      padding: @grid-gutter-width/3 0;
      border-bottom: 1px solid fade(@color-primary-pale, 30%);

      li {
        padding: .1em 0;
        list-style: none;
      }

      ul {
        margin: .3em 0 0 @grid-gutter-width;
      }
    }
  }

  .download-summary {
    padding: @grid-gutter-width/1.5;

    .title {
      font-size: @font-size-m;
      margin: 0;
    }

    ul {
      list-style: none;
      font-size: @font-size-s;
      margin: 0;
      padding: .4rem 0;
    }

    li {
      padding: .2em 0;
      vertical-align: baseline;
    }
  }

  .download-button {
    margin-top: auto;
    position: absolute;
    bottom: 0;
  }
}

.show-datasets {
  
  .map-container {
    width: 100% - @drawer-width;
  }

  .datasets-drawer {
    width: @drawer-width;

    .download-section {
      display: flex;
    }
  }

  .datasets-list-small {
    display: block;
  }
}

.list-view {

  &.map-search {
    height: auto;
    min-height: 100vh;
  }

  .results {
    

    .list-container {
      width: 100%;
      overflow-y: auto;
    }
  }

  .map-container, .datasets-drawer {
    display: none;
  }

  .download-section {
    position: fixed;
    left: 100% - @drawer-width;
    box-shadow: 0px -1px 6px fade(@color-neutral-dark, 30%), 0px -1px 1px fade(@color-neutral-dark, 20%);

    .download-toggle {
      box-shadow: 0px -1px 6px fade(@color-neutral-dark, 30%), 0px -1px 1px fade(@color-neutral-dark, 20%);
    }

    &:not(.collapsed) {
      top: calc(~"100vh - 400px");
    }
  }
}

.map-view {

  .results {
    flex: 1 1 auto;
  }

  .list-container {
    display: none;
  }

  .map-div {
    height: 100%;
  }
}

.map-search-content + .site-footer {
  display: none;
}

@media (max-width: @screen-default) {

  @new-drawer-width: 50%;
  .show-datasets {
  
    .map-container {
      width: 100% - @new-drawer-width;
    }

    .datasets-drawer {
      width: @new-drawer-width;
    }

    .datasets-list-small {
      display: block;
    }
  }

}

@media (max-width: @screen-tablet) {

  .map-search-content {
    margin: 0 @grid-gutter-width*-1;
  }

  @new-drawer-width: 80%;
  .show-datasets {
  
    .map-container {
      width: 100% - @new-drawer-width;
    }

    .datasets-drawer {
      width: @new-drawer-width;
    }

    .datasets-list-small {
      display: block;
    }
  }
}
