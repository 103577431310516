.state-container {
  .page-container;
  .page-preload {
    display: block;
    height: 400px;
  }

  .module-content {
    .entry {
      font-weight: bold;
      font-size: 1.2em;
    }
    .number { font-size: 1.5em; }
  }

  .datasets-list {
    background: white;
    padding: 0;

    .dataset-info {
      margin: 0;
    }
  }

  .datasets-summary {
    display: flex;
    align-items: baseline;
    padding: @grid-gutter-width*2 @grid-gutter-width;

    .btn {
      margin-left: auto;
    }

    .badge-number {
      .title-xl;
      border-radius: 1em;
      margin-right: .4em;
      background: @color-action;
    }
  }

  .datasets-actions {
    display: flex;
    margin-left: auto;

    .action-item {
      margin-left: 10px;
    }
  }

  @media (max-width: @screen-tablet) {
    .datasets-list .dataset-info {
      padding-left: @grid-gutter-width;
    }
  }
}
