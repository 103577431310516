.nav-pills-arrow(@color: @color-primary-pale, @size: 10px) {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - @size/2);
  width: @size;
  height: @size;
  transform: rotate(45deg);
  background: @color;
  bottom:@size/-2;
  box-shadow: -1px -1px 1px fade(@color-neutral-dark, 50%), 1px 1px 0 fade(@color-white, 20%) inset;
}

.masthead,
.account-masthead {
  background: @color-primary;
}

.masthead {
  box-shadow: 0 1px 0 fade(@color-white, 10%), 0 @masthead-border-size 0 @color-primary-pale, 0 -1px 2px fade(@color-neutral-dark, 50%) inset;
  z-index: 10;
  margin-bottom: @masthead-border-size;

  .container-fluid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  }

  .btn-navbar {
    margin-left: auto;
    background: @color-action;

    &:hover,
    &:active,
    &:focus {
      background: lighten(@color-primary, 4%);
      outline: none;
      box-shadow: none;
    }

    .icon-bar {
      box-shadow: none;
    }
  }

  @media (max-width: @screen-tablet) {
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;
  }

  @media(max-width: @screen-default) {
    .nav-collapse {
      width: 100vw;
      margin-left: -@grid-gutter-width;
      margin-right: -@grid-gutter-width;

      &.in,
      &.show {
        margin-top: @unit;
        border-top: 1px solid lighten(@color-primary, 4%);
      }
    }
    .header-image {
      margin-top: 10px;
    }
  }

  @media(min-width: @screen-default) {
    .container-fluid {
      .nav-collapse {
        margin-left: auto;
        padding-top: @grid-gutter-width;
      }
    }
  }

  .navigation {
    margin: 0;

    @media (max-width: @screen-default) {
      margin-bottom: @grid-gutter-width/2;
    }

    .nav {
      margin: 0;
    }

    .nav-pills {
      li {
        a {
          .title-m;
          color: @color-white;
          position: relative;
          padding-right: 10px;
          @media (min-width: @screen-default) {
            padding-bottom: @grid-gutter-width;
          }
          &:hover,
          &:focus {
            color: @color-action-light;
            background-color: transparent;
          }

          margin-bottom: 0;
        }
        &.active {
          a {
            color: @color-action-light;
            background-color: transparent;
            @media (min-width: @screen-default) {
              &:after {
                .nav-pills-arrow;
              }
            }
            box-shadow: none;
          }
        }
      }
    }
  }
  .show {
    height: auto;
  }
}

.account-masthead {
  background: lighten(@color-primary, 4%);
  .account {
    ul {
      li {
        border: 0;
        a {
          font-size: @base-font-size;
          font-weight: 400;
          color: @color-white;
          &:hover {
            color: @color-action-light;
            background: none;
          }
        }
      }

      .image img.gravatar {
        border-radius: 20px;
      }

      .notifications {
        a {
          .badge {
            font-size: @base-font-size;
            font-weight: 400;
            color: @color-white;
            background: none;
            margin: 0;
          }
        }

        &.notifications-important {
          a {
            .badge {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}

.header-text-logo-tagline {
  max-width: @grid-gutter-width*20;
  margin-top: -@grid-gutter-width/2;
  margin-bottom: @grid-gutter-width;
}

.logo {
  display: inline-block;
  max-width: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}
.debug {
  display: none;
}
