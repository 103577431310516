.home-nav {
  position: absolute;
  top: 0;
  width: 100%;
  margin-bottom: 10vh;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: min-height .6s;
  z-index: 10000;

  .account-masthead,
  .masthead {
    background: none;
    box-shadow: none;
    margin-bottom: 0;
    z-index: 10000;
  }

  .navigation {

    .nav-pills li a {
      font-size: @font-size-l;
    }

    .nav-pills li.active a:after {
      content: none;
    }
  }

  .bg-image {
    position: fixed;
    width: 100%;
    height: 90vh;
    transition: height .6s;
  }

  .header-image {
    display: none;
  }

  .navbar {
    transition: all .6s;
    position: absolute;
    top: calc(~"90vh - 8rem");
    width: 100%;
  }

  &+div {
    margin-top: 100vh;
    transition: margin .6s;
  }
}

.header-banner {
  margin: 0 auto;
  text-align: center;
  color: @color-white;
  padding-top: 15vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: top .6s;
  z-index: 9000;

  .government-logo {
    position: absolute;
    left: @grid-gutter-width;
    top: @grid-gutter-width;
    width: 100px;
  }

  .grid-nigeria-logo {
    width: 100%;
    max-width: 350px;
    margin: 0;
  }

  .banner-title {
    .headline-xxl;
    font-weight: 700;
    margin: 6vh 20%;
  }
}

.header-search {
  position: relative;
  margin-left: @grid-gutter-width;
  width: 40%;
  transition: margin .4s;

  input {
    background: fade(@color-primary-dark, 50%);
    border: 0;
    font-size: @font-size-xl;
    color: @color-white;
    height: auto;
    width: 100%;
    padding: .9em 1em .9em 2.5em;
    border-radius: @base-border-radius;
    transition: padding .6s;
    margin: 0;
  }

  ::placeholder {
    color: fade(@color-white, 50%);
  }

  i {
    position: absolute;
    left: @grid-gutter-width;
    top: .9em;
    font-size: @font-size-xl;
  }
}

.home-nav.collapsed {
  min-height: 5rem;

  .header-banner {
    top: -90vh;
  }

  .bg-image {
    height: 6.5rem;
  }

  .account-masthead {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background: lighten(@color-primary, 4%);
  }

  .navbar {
    position: fixed;
    top: 2rem;
    width: 100%;
    height: 4.5rem;
    background: fade(@color-primary, 80%);
    box-shadow: 0 1px 2px fade(@base-text-color, 50%);
  }


  .btn-navbar {
    padding: 10px;
  }

  .header-search {

    form {
      margin: 0;
    }

    input {
      padding: .6em 1em .6em 2.5em;
      font-size: @font-size-l;
    }

    i {
      top: .7em;
      font-size: @font-size-l;
    }
  }

  &+div {
    margin-top: 15rem;
  }

  .scroll-down {

    display: none;
  }
}

.scroll-down {
  position: absolute;
  left: calc(~"50% - 2.5rem");
  top: 89vh;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  transition: all .6s;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    border-bottom: 3px solid @color-action;
    border-right: 3px solid @color-action;
    transform: rotate(45deg);
    margin-bottom: .8rem;
    transition: width .6s, height .6s;
  }

  &:hover {
    background: @color-action;

    &::before {
      border-color: @color-white;
    }
  }
}

.teaser {
  margin-bottom: @grid-gutter-width;

  .teaser-heading {
    border-top: 1px solid @color-primary-pale;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &:hover {
      text-decoration: none;

      .title-xl {
        color: @color-action;
      }

      .icon-linkto {
        .icon-hover;
      }
    }
  }

  .title-xl {
    color: @base-text-color;
  }

  p {
    font-size: @font-size-m;
  }

  .icon-linkto {
    .icon-linkto(@size:20px);
    margin-right: @unit;
    flex: 0 0 auto;
  }
}

.support-teaser {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8% 35% 5%;
  color: @color-white;
}

.widget-headline {
  .headline-xxl;
  b {
    font-weight: 400;
  }
  .badge-number {
    font-size: @font-size-xl;
    border-radius: 1em;
    line-height: 1.1em;
    height: auto;
    background: @color-action;
  }
}

.map-sector-widget {
  display: flex;
  width: 100%;
  padding: @grid-gutter-width 0;
}

.home-map {
  min-height: 360px;
  flex:.8;
  position: relative;

  .leaflet-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: none;
  }

  .leaflet-tooltip {
    .title-m;
    padding: @grid-gutter-width/2 @grid-gutter-width;
    font-family: @base-font-family;
    transition: @leaflet-transition-time opacity ease-in-out 20ms;
  }
}

.home-sectors {
  flex:1.2;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.home-sector-item {
  width: 46%;
  padding: 0;
  margin-left: 4%;
  display: flex;
  align-items: center;
  border-top: 1px solid fade(@color-primary-pale, 30%);

  .badge-number {
    font-size: @font-size-m;
    border-radius: 1em;
    line-height: 1.2em;
    background: @color-action;
  }

  .image {
    width: 70px;
    padding: 0 5px;
  }

  &:hover {
    cursor: pointer;
    color: @color-action-dark;
    .badge-number {
      .icon-hover;
    }
  }
}

.home-sector-heading {
  .title-m;
  line-height: 1.3em;
}

.divider {
  width: 100%;
  margin: @grid-gutter-width 0 @grid-gutter-width*2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before,
  &::after {
    content: '';
    display: block;
    background: @color-action;
    flex:1;
    height: 6px;
  }

  .btn {
    margin: 0 @grid-gutter-width;
    padding: .8em 2em;
  }
}



@media (max-width: @screen-default) {

  .home-nav .nav-collapse.show {
    background: @color-primary;
    padding-left: @unit*2.5;
  }

  .header-search {
    width: 70vw;
    margin-left: 0;
  }

  .home-nav  .btn-navbar {
    padding: 15px;
    margin-top: 0;
    &:focus,
    &:hover {
      margin-top: 0;
    }

    & .icon-bar {
      width: 22px;
      &+.icon-bar {
        margin-top: 6px;
      }
    }
  }

  .home-nav.collapsed {
    .navbar {
      padding-top: 10px;
    }
  }
}

@media (max-width: @screen-tablet) {

  .support-teaser {
    padding: 8% 20% 5%;
  }

  .home-nav  {
    margin-left: @grid-gutter-width*-1;
    margin-right: @grid-gutter-width*-1;
    padding: 0 @grid-gutter-width;
  }

  .header-banner {

    .banner-title {
      .title-xl;
      font-size: @font-size-xl;
      margin: 6vh 10%;
    }
    .government-logo {
      width: 70px;
    }
    .grid-nigeria-logo {
      width: 70%;
      max-width: 300px;
    }
  }

  .map-sector-widget {
    flex-direction: column;
  }

  .home-sector-item {
    margin: 0 2.5%;
    width: 100%;
  }
}

@media (max-width: @screen-phone) {

  .header-banner {
    margin: 0 auto;
  }

  .home-nav  {

    .navbar {
      top: calc(~"90vh - 6rem");
    }

    .btn-navbar {
      padding: 10px;
    }
  }

  .header-search {

    input {
      padding: .6em 1em .6em 2.5em;
      font-size: @font-size-l;
    }

    i {
      top: .7em;
      font-size: @font-size-l;
    }
  }

}
