.states-overview {

  .search-form {
    padding: 0;
    border: 0;
    margin: 0;
  }

  .search-form .search-input {
    background: @color-neutral-pale;

    input {
      width: 100%;
      border: 0;
      box-shadow: none;
      font-size: @font-size-m;
    }
  }

  .search-heading {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .heading-xl {
      margin-bottom: @unit;
    }
  }
}
