@import "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&amp;subset=latin-ext";
@import "https://fonts.googleapis.com/css?family=Raleway:300,700";
/*!
 * Bootstrap v2.3.2
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @mdo and @fat.
 */
.icon-linkto {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #67D699;
  position: relative;
  transition: box-shadow 0.2s;
}
.icon-linkto::before {
  content: "";
  display: block;
  position: absolute;
  width: 22%;
  height: 22%;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  top: 30%;
  right: 30%;
}
.icon-linkto::after {
  content: "";
  display: block;
  position: absolute;
  height: 50%;
  width: 10%;
  width: 2px;
  background: #fff;
  top: 30%;
  right: 29%;
  transform: rotate(45deg);
  transform-origin: top left;
}
.icon-hover {
  box-shadow: 0 0 0 0.3em #67D699;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.headline-xxl {
  font-family: 'Raleway', sane-serif;
  font-size: 2.9rem;
  font-weight: 300;
  line-height: 1.3em;
  margin-bottom: 20px;
}
.headline-xl {
  font-family: 'Raleway', sane-serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.3em;
  margin-bottom: 20px;
}
.title-xl {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: .02em;
  margin-bottom: 20px;
}
.title-l {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: .02em;
}
.title-m {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
}
.heading-xl {
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: .02em;
}
.heading-l {
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: .02em;
}
.heading-m {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: .02em;
}
.heading-s {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: .02em;
}
.select-label {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  color: #114A29;
}
.badge {
  font-size: 0.9rem;
}
.badge-number {
  min-width: 1.5em;
  height: 1.5em;
  padding: .2em;
  text-align: center;
  background: #96AD87;
  text-shadow: none;
  transition: box-shadow 0.2s;
}
.text-m {
  font-size: 16px;
}
.text-light {
  font-weight: 300;
  letter-spacing: .02em;
}
.check-default {
  font-weight: 400;
  margin: 0;
  display: inline-block;
}
.check-default::after {
  content: none;
}
.check-default span {
  color: #4A4A4A;
  align-items: flex-start;
  display: flex;
}
.check-default span::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  text-align: center;
  color: transparent;
  line-height: 1.3em;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 2px solid #e3e3e3;
  margin-right: .6em;
}
.check-default input {
  display: none;
}
.check-default input:checked + span::before {
  background: #67D699;
  border-color: transparent;
  color: #fff;
}
.radio-default {
  font-weight: 400;
  margin: 0;
  display: inline-block;
}
.radio-default::after {
  content: none;
}
.radio-default span {
  color: #4A4A4A;
  align-items: flex-start;
  display: flex;
}
.radio-default span::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  text-align: center;
  color: transparent;
  line-height: 1.3em;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 2px solid #e3e3e3;
  margin-right: .6em;
}
.radio-default input {
  display: none;
}
.radio-default input:checked + span::before {
  background: #67D699;
  border-color: transparent;
  color: #fff;
}
.radio-default span::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: .3em;
}
.check-resources {
  font-weight: 400;
  margin: 0;
  display: inline-block;
}
.check-resources::after {
  content: none;
}
.check-resources span {
  color: #4A4A4A;
  align-items: flex-start;
  display: flex;
}
.check-resources span::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  text-align: center;
  color: transparent;
  line-height: 1.3em;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 2px solid #e3e3e3;
  margin-right: .6em;
}
.check-resources input {
  display: none;
}
.check-resources input:checked + span::before {
  background: #67D699;
  border-color: transparent;
  color: #fff;
}
.check-resources span {
  color: #b0b0b0;
  word-break: break-word;
}
.check-resources input:checked + span {
  color: #4A4A4A;
}
.check-resources:hover span {
  color: #4A4A4A;
}
.check-resources:hover span::before {
  box-shadow: 0 0 0 0.2em rgba(103, 214, 153, 0.5);
}
.check-resources-small {
  font-weight: 400;
  margin: 0;
  display: inline-block;
}
.check-resources-small::after {
  content: none;
}
.check-resources-small span {
  color: #4A4A4A;
  align-items: flex-start;
  display: flex;
}
.check-resources-small span::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  text-align: center;
  color: transparent;
  line-height: 1.3em;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 2px solid #e3e3e3;
  margin-right: .6em;
}
.check-resources-small input {
  display: none;
}
.check-resources-small input:checked + span::before {
  background: #67D699;
  border-color: transparent;
  color: #fff;
}
.check-resources-small span {
  color: #b0b0b0;
  word-break: break-word;
}
.check-resources-small input:checked + span {
  color: #4A4A4A;
}
.check-resources-small:hover span {
  color: #4A4A4A;
}
.check-resources-small:hover span::before {
  box-shadow: 0 0 0 0.2em rgba(103, 214, 153, 0.5);
}
.check-resources-small span::before {
  font-size: 8px;
  width: 9px;
  height: 9px;
  margin-right: .6em;
  margin-top: .3em;
}
.label[data-format*=json],
.label[data-format*=JSON] {
  background-color: #F26522;
}
.label[data-format*=csv],
.label[data-format*=CSV] {
  background-color: #FCAF17;
}
.label[data-format*=xls],
.label[data-format*=XLS] {
  background-color: #AB3933;
}
body,
html {
  font-family: 'Roboto Condensed', sans-serif;
  color: #4A4A4A;
  font-size: 14px;
  line-height: 1.3em;
  letter-spacing: .01em;
  background: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
a {
  color: #38B370;
}
a:hover,
a:focus {
  color: #67D699;
}
a.link {
  background: rgba(103, 214, 153, 0);
  box-shadow: 0 0 0 1em #fff inset;
  transition: box-shadow 0.1s, background 0.1s;
  border-radius: 2px;
  padding: .1em .2em;
  margin: -0.1em -0.2em;
}
a.link:hover,
a.link:focus {
  color: #fff;
  background: #67D699;
  text-decoration: none;
  box-shadow: 0 0 0 0 #fff inset;
}
p {
  line-height: 1.4em;
  margin-bottom: .8em;
}
li,
label {
  line-height: 1.3em;
}
@media (min-width: 767px) {
  [role=main],
  .main {
    background: none;
  }
}
@media (min-width: 1200px) {
  .row-fluid {
    margin: auto;
    max-width: 1600px;
  }
}
.wrapper {
  border: none;
  box-shadow: none;
  background: none;
}
@media (max-width: 767px) {
  .wrapper {
    margin: auto;
  }
}
@media (min-width: 767px) {
  .wrapper .primary {
    float: right;
    margin-left: 2.56410256%;
  }
  .wrapper .primary div.module-content {
    padding-left: 25px;
  }
  .wrapper .primary .page-header {
    margin-left: inherit;
  }
  .wrapper .secondary {
    margin-left: 0;
  }
}
.label {
  height: 13px;
  font-size: 9px;
  border-radius: 13px;
  padding: 0 .4em;
  text-shadow: none;
  margin: 0 .6em;
}
@media (max-width: 767px) {
  .container-fluid {
    margin: 0 -20px;
  }
}
@media (max-width: 1200px) {
  .row-fluid .span10.offset1 {
    margin-left: auto;
    width: 100%;
  }
}
.dropdown label {
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
}
.nav-tabs {
  border-bottom: 6px solid rgba(150, 173, 135, 0.2);
  padding-bottom: 1px;
  margin-bottom: 0;
}
.nav-tabs a.active,
.nav-tabs a.active:hover,
.nav-tabs a.active:focus,
.nav-tabs li.active a,
.nav-tabs li.active a:hover,
.nav-tabs li.active a:focus {
  border: 0;
  background: rgba(150, 173, 135, 0.2);
}
.nav-tabs li {
  margin: 0;
  cursor: pointer;
}
.nav-tabs li a {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  border: 0;
  border-radius: 0;
  padding: 20px 20px 10px;
  color: #4A4A4A;
  margin-right: 1px;
}
.page-header,
.page-header .nav-tabs {
  border: 0;
}
.tab-content {
  background: #f7f8f9;
}
.tab-content .activity {
  margin: 20px;
}
.box-grid {
  border-top: 1px solid rgba(150, 173, 135, 0.3);
  padding: 20px 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  position: relative;
  min-height: 10rem;
}
@media (max-width: 1200px) {
  .box-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .box-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 480px) {
  .box-grid {
    grid-template-columns: 1fr;
  }
}
.box {
  border: 0;
  border-radius: 2px;
  background: #f7f8f9;
  box-shadow: 1px 1px 1px rgba(74, 74, 74, 0.2);
  transition: box-shadow 0.4s;
}
.box-v {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
.box-v a {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.box-v .image {
  height: 160px;
  mix-blend-mode: multiply;
}
.box-v .image img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.box-v .info {
  color: #4A4A4A;
}
.box-v .dataset-count {
  margin-top: auto;
  margin-bottom: 0;
  padding-top: .8rem;
  border-top: 1px solid rgba(150, 173, 135, 0.3);
  color: #4A4A4A;
}
.box-v .badge-number {
  padding: .35em .3em;
  font-size: 16px;
  border-radius: 1em;
  margin-right: .4em;
  background: #67D699;
}
.box-v:hover {
  cursor: pointer;
  box-shadow: 1px 2px 3px rgba(74, 74, 74, 0.4);
}
.box-v:hover a {
  text-decoration: none;
}
.box-v:hover .badge-number {
  box-shadow: 0 0 0 0.3em #67D699;
}
.box-v:hover .info h2 {
  color: #54C286;
}
.search-form .search-input input {
  padding: .9rem 0 .5rem 2.6rem;
  background: transparent;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  width: 100%;
}
.search-form .search-input .fa-search {
  position: absolute;
  color: #96AD87;
  font-size: 1.4rem;
  padding: .7rem .5rem;
}
.search-form .search-input button i {
  color: #67D699;
  font-size: 14px;
}
input[type=radio],
input[type=checkbox] {
  top: 3px;
}
select,
input {
  font-family: inherit;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  height: 34px;
  padding: .4em .8em;
}
textarea {
  height: auto;
}
.input-append .add-on,
.input-prepend .add-on {
  height: 34px;
}
.btn {
  text-shadow: none;
  box-shadow: none;
  background: #67D699;
  border: 0;
  border-color: transparent;
  border-radius: 2px;
  padding: .2em .4em;
  letter-spacing: .03em;
  color: #fff;
  transition: box-shadow 0.2s, background 0.2s;
  box-shadow: 0 0 0 0 #54C286, 0 0 0 20px #67D699 inset;
}
.btn:hover,
.btn:focus {
  background: #54C286;
  color: #fff;
  box-shadow: 0 0 0 1px #54C286, 0 0 0 0px #67D699 inset;
  transition: box-shadow 0.2s;
}
.btn .fa {
  margin-right: 5px;
}
.btn-large {
  font-size: 1.4rem;
  padding: .7em 1.4em;
}
button {
  font-family: inherit;
  outline: none;
}
.btn-primary {
  background: #67D699;
  padding: .5em 1.2em;
  box-shadow: 0 0 0 0 #54C286, 1px 1px 1px rgba(74, 74, 74, 0.3), 0 0 0 30px #67D699 inset;
}
.btn-primary:hover,
.btn-primary:focus {
  background: #54C286;
  box-shadow: 0 0 0 1px #54C286, 1px 1px 1px 1px rgba(74, 74, 74, 0.4), 0 0 0 0px #67D699 inset;
  transition: box-shadow 0.2s;
}
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus {
  background: #b0b0b0;
  box-shadow: none;
}
.btn-default {
  background: #96AD87;
  box-shadow: 0 0 0 0 #b0b0b0, 0 0 0 20px #96AD87 inset;
  padding: .5em 1.2em;
}
.btn-default:hover,
.btn-default:focus {
  background: #7b9869;
  box-shadow: 0 0 0 1px #7b9869, 0 0 0 0px #96AD87 inset;
}
.list-buttons li {
  margin-bottom: 15px;
}
.filter,
.dropdown {
  display: flex;
  align-items: center;
  position: relative;
}
.filter-label {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: .02em;
  display: block;
  white-space: nowrap;
}
.dropdown {
  align-items: baseline;
}
.dropdown .dropdown-label {
  font-size: 14px;
  font-weight: 300;
}
.dropdown .grid3-select {
  min-width: 140px;
}
.dropdown .grid3-select .select__single-value {
  padding: 4px 0;
  padding: 0;
  margin: 0;
  position: relative;
  transform: none;
  color: #54C286;
  max-width: 100%;
}
.dropdown .grid3-select .select__indicator {
  padding: 0px 4px 8px;
}
.filter .grid3-select {
  position: static;
  width: 100%;
}
.grid3-select .select__control {
  border: 0;
  box-shadow: none;
  min-width: 30px;
  min-height: 28px;
  width: 100%;
  position: static;
  display: flex;
  align-items: baseline;
  background: none;
  cursor: pointer;
}
.grid3-select .select__control:hover {
  border: 0;
  box-shadow: none;
}
.grid3-select .select__value-container {
  padding: 0 24px 0 8px;
}
.grid3-select .select__indicator-separator {
  display: none;
}
.grid3-select .select__indicators {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.grid3-select .select__control--is-focused .select__indicators {
  left: auto;
  width: 24px;
}
.grid3-select .select__indicator {
  padding: 4px;
}
.grid3-select .select__indicator svg {
  fill: #67D699;
}
.grid3-select input,
.grid3-select input:focus {
  box-shadow: none;
  margin: 0;
  height: auto;
}
.grid3-select .select__multi-value {
  background: rgba(103, 214, 153, 0.5);
  border-radius: 1em;
  padding: 0 2px;
}
.grid3-select .select__multi-value__label {
  font-size: 14px;
  color: #4A4A4A;
}
.grid3-select .select__placeholder {
  background: rgba(103, 214, 153, 0.5);
  border-radius: 1em;
  padding: 0 2px;
  font-size: 14px;
  color: #4A4A4A;
  background: rgba(103, 214, 153, 0.3);
  display: block;
  padding: 3px 10px;
  text-transform: uppercase;
  font-weight: 700;
}
.grid3-select .select__placeholder:empty {
  display: none;
}
.grid3-select .select__control--is-focused .select__placeholder {
  display: none;
}
.grid3-select .select__multi-value__remove svg {
  fill: #1D8449;
}
.grid3-select .select__multi-value__remove:hover {
  background: none;
}
.grid3-select .select__multi-value__remove:hover svg {
  fill: #114A29;
}
.grid3-select .select__menu {
  right: 0;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(74, 74, 74, 0.3), 0 1px 4px rgba(74, 74, 74, 0.3);
  min-width: 10rem;
}
.grid3-select .select__menu-list {
  padding: 10px 0;
}
.grid3-select .select__option {
  font-size: 14px;
  color: #4A4A4A;
  padding: 0.3em 15px;
}
.grid3-select .select__option:focus,
.grid3-select .select__option:active,
.grid3-select .select__option--is-focused {
  background-color: transparent;
  color: #54C286;
}
.grid3-select .select__option--is-selected {
  background-color: rgba(103, 214, 153, 0.3);
  color: #4A4A4A;
}
.loading-overlay {
  padding: 0 50px 50px 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  background-color: #f7f8f9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-overlay.hide {
  display: none;
}
@keyframes dot {
  0% {
    opacity: 0;
    top: 25px;
    background: #54C286;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
    top: 0;
    background: #1D8449;
  }
  100% {
    opacity: 0;
    top: 0;
    background: #1D8449;
  }
}
.loading-overlay .dot1,
.loading-overlay .dot2,
.loading-overlay .dot3,
.loading-overlay .dot4,
.loading-overlay .dot5,
.loading-overlay .dot6 {
  width: 50px;
  height: 50px;
  position: absolute;
}
.loading-overlay .dot1:before,
.loading-overlay .dot2:before,
.loading-overlay .dot3:before,
.loading-overlay .dot4:before,
.loading-overlay .dot5:before,
.loading-overlay .dot6:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  background: #1D8449;
  width: 10px;
  height: 10px;
  left: 10px;
  top: 10px;
  opacity: 0;
  animation: dot 1.4s infinite;
}
.loading-overlay .dot2 {
  transform: rotate(60deg);
}
.loading-overlay .dot2:before {
  animation-delay: 0.23333333s;
}
.loading-overlay .dot3 {
  transform: rotate(120deg);
}
.loading-overlay .dot3:before {
  animation-delay: 0.46666667s;
}
.loading-overlay .dot4 {
  transform: rotate(180deg);
}
.loading-overlay .dot4:before {
  animation-delay: 0.7s;
}
.loading-overlay .dot5 {
  transform: rotate(240deg);
}
.loading-overlay .dot5:before {
  animation-delay: 0.93333333s;
}
.loading-overlay .dot6 {
  transform: rotate(300deg);
}
.loading-overlay .dot6:before {
  animation-delay: 1.16666667s;
}
.map-container .loading-overlay {
  padding: 0 80px 80px 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  background-color: #f7f8f9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(29, 132, 73, 0.3);
}
.map-container .loading-overlay.hide {
  display: none;
}
@keyframes dot {
  0% {
    opacity: 0;
    top: 40px;
    background: #54C286;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
    top: 0;
    background: #1D8449;
  }
  100% {
    opacity: 0;
    top: 0;
    background: #1D8449;
  }
}
.map-container .loading-overlay .dot1,
.map-container .loading-overlay .dot2,
.map-container .loading-overlay .dot3,
.map-container .loading-overlay .dot4,
.map-container .loading-overlay .dot5,
.map-container .loading-overlay .dot6 {
  width: 80px;
  height: 80px;
  position: absolute;
}
.map-container .loading-overlay .dot1:before,
.map-container .loading-overlay .dot2:before,
.map-container .loading-overlay .dot3:before,
.map-container .loading-overlay .dot4:before,
.map-container .loading-overlay .dot5:before,
.map-container .loading-overlay .dot6:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  background: #1D8449;
  width: 14px;
  height: 14px;
  left: 14px;
  top: 14px;
  opacity: 0;
  animation: dot 1.4s infinite;
}
.map-container .loading-overlay .dot2 {
  transform: rotate(60deg);
}
.map-container .loading-overlay .dot2:before {
  animation-delay: 0.23333333s;
}
.map-container .loading-overlay .dot3 {
  transform: rotate(120deg);
}
.map-container .loading-overlay .dot3:before {
  animation-delay: 0.46666667s;
}
.map-container .loading-overlay .dot4 {
  transform: rotate(180deg);
}
.map-container .loading-overlay .dot4:before {
  animation-delay: 0.7s;
}
.map-container .loading-overlay .dot5 {
  transform: rotate(240deg);
}
.map-container .loading-overlay .dot5:before {
  animation-delay: 0.93333333s;
}
.map-container .loading-overlay .dot6 {
  transform: rotate(300deg);
}
.map-container .loading-overlay .dot6:before {
  animation-delay: 1.16666667s;
}
.loading-container .loading-overlay {
  padding: 0 36px 36px 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  background-color: #f7f8f9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  position: relative;
  padding: 50px 36px;
}
.loading-container .loading-overlay.hide {
  display: none;
}
@keyframes dot {
  0% {
    opacity: 0;
    top: 18px;
    background: #54C286;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
    top: 0;
    background: #1D8449;
  }
  100% {
    opacity: 0;
    top: 0;
    background: #1D8449;
  }
}
.loading-container .loading-overlay .dot1,
.loading-container .loading-overlay .dot2,
.loading-container .loading-overlay .dot3,
.loading-container .loading-overlay .dot4,
.loading-container .loading-overlay .dot5,
.loading-container .loading-overlay .dot6 {
  width: 36px;
  height: 36px;
  position: absolute;
}
.loading-container .loading-overlay .dot1:before,
.loading-container .loading-overlay .dot2:before,
.loading-container .loading-overlay .dot3:before,
.loading-container .loading-overlay .dot4:before,
.loading-container .loading-overlay .dot5:before,
.loading-container .loading-overlay .dot6:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  background: #1D8449;
  width: 8px;
  height: 8px;
  left: 8px;
  top: 8px;
  opacity: 0;
  animation: dot 1.4s infinite;
}
.loading-container .loading-overlay .dot2 {
  transform: rotate(60deg);
}
.loading-container .loading-overlay .dot2:before {
  animation-delay: 0.23333333s;
}
.loading-container .loading-overlay .dot3 {
  transform: rotate(120deg);
}
.loading-container .loading-overlay .dot3:before {
  animation-delay: 0.46666667s;
}
.loading-container .loading-overlay .dot4 {
  transform: rotate(180deg);
}
.loading-container .loading-overlay .dot4:before {
  animation-delay: 0.7s;
}
.loading-container .loading-overlay .dot5 {
  transform: rotate(240deg);
}
.loading-container .loading-overlay .dot5:before {
  animation-delay: 0.93333333s;
}
.loading-container .loading-overlay .dot6 {
  transform: rotate(300deg);
}
.loading-container .loading-overlay .dot6:before {
  animation-delay: 1.16666667s;
}
.loading-icon .loading-overlay {
  padding: 0 24px 24px 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  background-color: #f7f8f9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  position: relative;
  left: -6px;
}
.loading-icon .loading-overlay.hide {
  display: none;
}
@keyframes dot {
  0% {
    opacity: 0;
    top: 12px;
    background: #54C286;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
    top: 0;
    background: #1D8449;
  }
  100% {
    opacity: 0;
    top: 0;
    background: #1D8449;
  }
}
.loading-icon .loading-overlay .dot1,
.loading-icon .loading-overlay .dot2,
.loading-icon .loading-overlay .dot3,
.loading-icon .loading-overlay .dot4,
.loading-icon .loading-overlay .dot5,
.loading-icon .loading-overlay .dot6 {
  width: 24px;
  height: 24px;
  position: absolute;
}
.loading-icon .loading-overlay .dot1:before,
.loading-icon .loading-overlay .dot2:before,
.loading-icon .loading-overlay .dot3:before,
.loading-icon .loading-overlay .dot4:before,
.loading-icon .loading-overlay .dot5:before,
.loading-icon .loading-overlay .dot6:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  background: #1D8449;
  width: 6px;
  height: 6px;
  left: 6px;
  top: 6px;
  opacity: 0;
  animation: dot 1s infinite;
}
.loading-icon .loading-overlay .dot2 {
  transform: rotate(60deg);
}
.loading-icon .loading-overlay .dot2:before {
  animation-delay: 0.16666667s;
}
.loading-icon .loading-overlay .dot3 {
  transform: rotate(120deg);
}
.loading-icon .loading-overlay .dot3:before {
  animation-delay: 0.33333333s;
}
.loading-icon .loading-overlay .dot4 {
  transform: rotate(180deg);
}
.loading-icon .loading-overlay .dot4:before {
  animation-delay: 0.5s;
}
.loading-icon .loading-overlay .dot5 {
  transform: rotate(240deg);
}
.loading-icon .loading-overlay .dot5:before {
  animation-delay: 0.66666667s;
}
.loading-icon .loading-overlay .dot6 {
  transform: rotate(300deg);
}
.loading-icon .loading-overlay .dot6:before {
  animation-delay: 0.83333333s;
}
.toolbar .breadcrumb {
  padding: 20px;
  font-size: 16px;
}
.toolbar .breadcrumb li {
  text-shadow: none;
  margin-right: 5px;
  color: #4A4A4A;
  font-weight: 300;
}
.toolbar .breadcrumb li:after {
  margin-left: 3.33333333px;
}
.toolbar .breadcrumb a {
  color: #54C286;
}
.module-heading,
.module-narrow .module-heading {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: .02em;
  background: none;
  padding: .5em 0;
  border: 0;
}
.module-heading .fa,
.module-narrow .module-heading .fa {
  margin-right: 5px;
}
.module-content.page-header .nav-tabs .fa {
  margin-right: 3.33333333px;
}
.module-content .pagination {
  margin: 40px auto;
}
.secondary .module-content:not(.box),
.left .module-content:not(.box) {
  border-bottom: 1px solid rgba(150, 173, 135, 0.3);
}
.secondary .module-content,
.left .module-content {
  padding: 10px 0;
  margin: 0;
}
.secondary .module-content .fa,
.left .module-content .fa {
  margin-right: 3.33333333px;
}
.secondary .module-content .heading,
.left .module-content .heading {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  margin: .2em 0;
}
.module-resource {
  margin-bottom: 20px;
}
.page_primary_action .btn {
  margin-right: 5px;
  margin-bottom: 10px;
}
.masthead,
.account-masthead {
  background: #1D8449;
}
.masthead {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1), 0 7px 0 #96AD87, 0 -1px 2px rgba(74, 74, 74, 0.5) inset;
  z-index: 10;
  margin-bottom: 7px;
}
.masthead .container-fluid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
}
.masthead .btn-navbar {
  margin-left: auto;
  background: #67D699;
}
.masthead .btn-navbar:hover,
.masthead .btn-navbar:active,
.masthead .btn-navbar:focus {
  background: #219552;
  outline: none;
  box-shadow: none;
}
.masthead .btn-navbar .icon-bar {
  box-shadow: none;
}
@media (max-width: 767px) {
  .masthead {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 980px) {
  .masthead .nav-collapse {
    width: 100vw;
    margin-left: -20px;
    margin-right: -20px;
  }
  .masthead .nav-collapse.in,
  .masthead .nav-collapse.show {
    margin-top: 10px;
    border-top: 1px solid #219552;
  }
  .masthead .header-image {
    margin-top: 10px;
  }
}
@media (min-width: 980px) {
  .masthead .container-fluid .nav-collapse {
    margin-left: auto;
    padding-top: 20px;
  }
}
.masthead .navigation {
  margin: 0;
}
@media (max-width: 980px) {
  .masthead .navigation {
    margin-bottom: 10px;
  }
}
.masthead .navigation .nav {
  margin: 0;
}
.masthead .navigation .nav-pills li a {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  color: #fff;
  position: relative;
  padding-right: 10px;
  margin-bottom: 0;
}
@media (min-width: 980px) {
  .masthead .navigation .nav-pills li a {
    padding-bottom: 20px;
  }
}
.masthead .navigation .nav-pills li a:hover,
.masthead .navigation .nav-pills li a:focus {
  color: #6FE0A2;
  background-color: transparent;
}
.masthead .navigation .nav-pills li.active a {
  color: #6FE0A2;
  background-color: transparent;
  box-shadow: none;
}
@media (min-width: 980px) {
  .masthead .navigation .nav-pills li.active a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(45%);
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: #96AD87;
    bottom: -5px;
    box-shadow: -1px -1px 1px rgba(74, 74, 74, 0.5), 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
  }
}
.masthead .show {
  height: auto;
}
.account-masthead {
  background: #219552;
}
.account-masthead .account ul li {
  border: 0;
}
.account-masthead .account ul li a {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.account-masthead .account ul li a:hover {
  color: #6FE0A2;
  background: none;
}
.account-masthead .account ul .image img.gravatar {
  border-radius: 20px;
}
.account-masthead .account ul .notifications a .badge {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background: none;
  margin: 0;
}
.account-masthead .account ul .notifications.notifications-important a .badge {
  margin-left: 3px;
}
.header-text-logo-tagline {
  max-width: 400px;
  margin-top: -10px;
  margin-bottom: 20px;
}
.logo {
  display: inline-block;
  max-width: 120px;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.debug {
  display: none;
}
.activity {
  background: none;
}
.activity .item::before,
.activity .item::after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #b0b0b0;
  position: absolute;
  left: 13px;
  top: -6px;
}
.activity .item::after {
  top: -12px;
}
.activity .item:first-child::before,
.activity .item:first-child::after {
  display: none;
}
.activity p {
  padding: .3rem 0;
}
.activity .item .icon {
  font-weight: 900;
}
.map-container .leaflet-touch .leaflet-control-layers,
.map-container .leaflet-touch .leaflet-bar {
  border: 0;
  background: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(74, 74, 74, 0.3);
}
.map-container .leaflet-touch .leaflet-control-layers a,
.map-container .leaflet-touch .leaflet-bar a {
  color: #67D699;
  border: 0;
  line-height: 1.3em;
  box-shadow: 0 0 0 16px #fff inset;
  transition: box-shadow 0.1s, background 0.1s;
  background: rgba(103, 214, 153, 0);
}
.map-container .leaflet-touch .leaflet-control-layers a:hover,
.map-container .leaflet-touch .leaflet-bar a:hover {
  color: #fff;
  background: #67D699;
  box-shadow: 0 0 0 0 #fff inset;
}
.map-container .leaflet-touch .leaflet-control-layers a,
.map-container .leaflet-touch .leaflet-bar a,
.map-container .leaflet-touch .leaflet-control-layers .leaflet-control-layers-toggle,
.map-container .leaflet-touch .leaflet-bar .leaflet-control-layers-toggle {
  width: 32px;
  height: 32px;
  background-size: 70%;
}
.map-container .leaflet-touch .leaflet-bar {
  display: flex;
}
.map-container .leaflet-touch .leaflet-bar a {
  font-weight: bold;
}
.map-container .leaflet-touch .leaflet-bar a:not(:last-child) {
  border-right: 1px solid rgba(74, 74, 74, 0.3);
}
.map-container .leaflet-tooltip {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  font-size: 14px;
  padding: 10px 20px;
  font-family: 'Roboto Condensed', sans-serif;
  transition: 150ms opacity ease-in-out 20ms;
}
.map-container .leaflet-tooltip label {
  font-weight: 300;
  display: inline;
}
.map-container .marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.map-container .marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
}
.map-container .marker-cluster span {
  line-height: 30px;
}
.map-container .marker-cluster-small {
  background-color: rgba(111, 224, 162, 0.3);
}
.map-container .marker-cluster-small div {
  background-color: rgba(111, 224, 162, 0.5);
}
.map-container .marker-cluster-medium {
  background-color: rgba(103, 214, 153, 0.4);
}
.map-container .marker-cluster-medium div {
  background-color: rgba(103, 214, 153, 0.6);
}
.map-container .marker-cluster-large {
  background-color: rgba(84, 194, 134, 0.5);
}
.map-container .marker-cluster-large div {
  background-color: rgba(84, 194, 134, 0.6);
}
.leaflet-right {
  display: flex;
  align-items: flex-end;
}
.leaflet-control-attribution {
  display: none;
}
.leaflet-popup {
  min-width: 300px;
}
.leaflet-popup-content-wrapper {
  border-radius: 2px !important;
}
.leaflet-popup-content {
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif !important;
  margin-top: 20px !important;
  padding-right: 20px;
}
.leaflet-popup-content a {
  font-weight: 700;
}
.leaflet-popup-content .btn {
  text-transform: uppercase;
  color: inherit;
  background: #dadfe3;
  margin-left: 10px;
}
.leaflet-popup-content table {
  min-width: 200px;
}
.leaflet-title {
  text-transform: uppercase;
  font-size: 22px;
}
.leaflet-title a {
  color: #1D8449 !important;
}
.leaflet-popup-close-button {
  margin: 10px;
}
.leaflet-control-layers form {
  margin-bottom: 0;
}
.leaflet-control-layers label.hidden {
  display: none;
}
.leaflet-control-layers .leaflet-control-layers-base,
.leaflet-control-layers .leaflet-control-layers-separator {
  display: none;
}
.leaflet-control-layers .leaflet-control-layers-overlays {
  max-height: 600px;
  overflow-y: auto;
  font-family: 'Roboto Condensed', sans-serif;
}
.leaflet-control-layers .leaflet-control-layers-overlays label {
  font-weight: 400;
  margin: 0;
  display: inline-block;
  display: block;
  margin: .5em 0;
}
.leaflet-control-layers .leaflet-control-layers-overlays label::after {
  content: none;
}
.leaflet-control-layers .leaflet-control-layers-overlays label span {
  color: #4A4A4A;
  align-items: flex-start;
  display: flex;
}
.leaflet-control-layers .leaflet-control-layers-overlays label span::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  text-align: center;
  color: transparent;
  line-height: 1.3em;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 2px solid #e3e3e3;
  margin-right: .6em;
}
.leaflet-control-layers .leaflet-control-layers-overlays label input {
  display: none;
}
.leaflet-control-layers .leaflet-control-layers-overlays label input:checked + span::before {
  background: #67D699;
  border-color: transparent;
  color: #fff;
}
.leaflet-control-layers .leaflet-control-layers-group label {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.leaflet-control-layers .leaflet-control-layers-selector {
  margin-right: 5px;
}
.leaflet-control-layers .leaflet-control-layers-selector + span {
  font-weight: 400;
}
.leaflet-control-layers .leaflet-control-layers-group-label {
  background: #96AD87;
  padding: 5px 10px;
  color: #fff;
}
.site-footer {
  border-top: 7px solid #38B370;
  background: #1D8449;
}
@media (max-width: 767px) {
  .site-footer {
    margin: 0 -20px;
    padding: 40px;
  }
}
.site-footer li {
  line-height: 1.3em;
  padding: .2em 0;
  font-weight: 700;
}
.site-footer a {
  color: #fff;
}
.site-footer a:hover,
.site-footer a:focus {
  color: #6FE0A2;
}
.site-footer .global-logo {
  width: 250px;
  margin-top: 10px;
  mix-blend-mode: screen;
}
.site-footer .government-logo img {
  width: 90px;
  margin-top: 20px;
}
.footer-content,
.footer-links {
  display: flex;
}
.footer-content > div,
.footer-links > div {
  flex: 1;
  margin-bottom: 10px;
}
@media (min-width: 980px) {
  .footer-partner {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding-left: 20px;
  }
}
@media (max-width: 980px) {
  .footer-content {
    flex-direction: column;
  }
}
.footer-title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  padding-bottom: .6em;
  color: rgba(255, 255, 255, 0.5);
}
.footer-disclaimer {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 20px -20px;
  padding: 20px;
  padding-bottom: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}
.footer-disclaimer p {
  margin-bottom: 5px;
}
/**
* temp modal
*/
.t-modal {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(74, 74, 74, 0.9);
  z-index: 999;
  flex-direction: column;
}
.t-modal-heading {
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: .02em;
}
label {
  font-weight: 400;
}
.t-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 1.4rem;
  color: #54C286;
}
.t-body {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.t-main {
  background: #fff;
  border-radius: 2px;
  max-width: 50rem;
  width: 80vw;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.t-row {
  margin-bottom: 20px;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
}
.t-row > div {
  flex: 1;
}
.t-row > div:not(:last-child) {
  margin-right: 1rem;
}
.t-row input,
.t-row textarea {
  width: 100%;
  resize: vertical;
  font-family: 'Roboto Condensed', sans-serif;
  background: #f7f8f9;
  border-radius: 2px;
  box-shadow: 1px 1px 2px 0 rgba(74, 74, 74, 0.2) inset;
  border: 0;
}
.table-requests th {
  text-transform: uppercase;
}
.table-requests > .fa {
  margin-right: 5px;
}
.table-requests > .fa:before {
  margin-right: 1px;
}
.table-requests tr:hover td {
  background-color: rgba(227, 227, 227, 0.2);
}
.table-requests .list-request-actions {
  margin: 10px 0 10px 40px;
  transition: 100ms opacity ease-in-out 20ms;
}
.table-requests .list-request-actions li {
  padding: 0;
  margin-right: 5px;
}
.table-requests .requester:before {
  display: inline-block;
  font-family: 'FontAwesome';
  content: '\f007';
  margin-right: 5px;
}
.request-type {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  margin-right: 20px;
}
.request-type.accepted .fa {
  background-color: #5bb75b;
  color: #fff;
}
.request-type.rejected .fa {
  background-color: #da4f49;
  color: #fff;
}
.request-type.pending .fa {
  background: #e3e3e3;
}
.request-type .fa {
  text-align: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  margin-right: 10px;
}
.input-tag {
  margin-top: 10px;
}
.import-tags {
  overflow: hidden;
  margin: 10px 0;
  background-color: #f7f8f9;
  border: 1px solid #e3e3e3;
  padding: 20px;
  border-radius: 2px;
}
.import-tags .fa-upload {
  margin-right: 5px;
}
.import-tags input[type=file] {
  max-width: 100%;
  color: #1D8449;
  padding: 5px;
}
.custom-tag .checkbox input,
.custom-tag .checkbox span {
  display: none;
}
.custom-tag .control-custom {
  margin-top: 10px;
  margin-bottom: 0;
}
.custom-tag .control-custom label {
  margin-bottom: 5px;
}
.custom-tag .control-custom input {
  width: 330px;
}
.notification.unread {
  background: #f7f8f9;
}
.notification-content {
  margin-bottom: 20px;
}
.notification .notification-title .notification-icon,
.notification.unread .notification-icon {
  color: #1D8449;
}
.notification-icon {
  margin-right: 10px;
}
.redux-toastr {
  background: #219552;
  display: block;
}
.redux-toastr .top-center {
  min-height: 70px;
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0;
  left: 0;
  top: 0;
  position: relative;
  transition: min-height 0.3s, padding 0.3s;
}
.redux-toastr .top-center:empty {
  min-height: 0;
  padding: 0 10px;
}
.redux-toastr .top-center .toastr {
  margin: 0 0 4px;
  border-radius: 2px;
  min-height: 60px;
}
.redux-toastr .top-center .toastr .rrt-middle-container {
  font-family: 'Roboto Condensed', sans-serif;
}
.map-search-content {
  padding: 0;
  border-bottom: 20px solid #96AD87;
}
.map-search {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.map-search #nigeria_map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.map-search_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  top: -20px;
  z-index: 2;
  flex: 0 0 auto;
  background: #96AD87;
  padding-top: 20px;
  padding-bottom: 0;
  color: #fff;
}
.map-search_header .dataset-count {
  font-weight: 300;
  margin-right: 20px;
}
.map-search_tabs {
  display: flex;
  width: 20vw;
  min-width: 280px;
}
.map-search_tabs .tab {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: .02em;
  padding: 10px;
  flex: 1;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px 2px 0 0;
  margin-right: 1px;
  cursor: pointer;
}
.map-search_tabs .tab i {
  margin-right: .5em;
}
.map-search_tabs .tab.active,
.map-search_tabs .tab:hover {
  background: #fff;
  color: #4A4A4A;
}
.map-search_filters {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 5px;
}
.map-search_filters .filter {
  font-size: 16px;
  color: #114A29;
  border-left: 1px solid rgba(150, 173, 135, 0.3);
  padding: 0 0 0 15px;
}
.map-search_container {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 6px;
  z-index: 0;
}
.map-search_sectors {
  display: flex;
  flex-direction: column;
  width: 20vw;
  min-width: 280px;
}
.map-search_sectors .top-bar {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  color: #114A29;
  flex: 0 0 auto;
  min-height: 40px;
  background: #f7f8f9;
  margin: 6px;
  padding: .9rem .5rem 0;
}
.map-search_sectors .sectors {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 6px;
}
.map-search_sectors ul {
  margin: 0;
  padding: 0 6px;
  list-style: none;
}
.map-search_sectors .sector {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 5px 13.33333333px;
  cursor: pointer;
  transition: opacity .2s, padding .1s;
}
.map-search_sectors .sector .image {
  width: 24%;
  height: auto;
  padding-right: .4em;
  position: relative;
  left: -4px;
  transition: width .1s;
}
.map-search_sectors .sector img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.map-search_sectors .sector .info {
  width: 76%;
}
.map-search_sectors .sector .heading {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  margin: .2em 0 .4em 0;
}
.map-search_sectors .sector .badge-number {
  position: absolute;
  top: 25%;
  right: 6px;
  transition: top .1s;
}
.map-search_sectors .sector .resources {
  width: 100%;
  padding: .5em 0 0 1em;
  border-top: 1px solid rgba(150, 173, 135, 0.3);
  margin-top: .5em;
  max-height: 300px;
  transition: max-height 0.2s;
  overflow: hidden;
}
.map-search_sectors .sector .resources li {
  padding: .1em 0;
}
.map-search_sectors .sector.selected {
  border-radius: 2px;
  background: #f7f8f9;
  box-shadow: 1px 1px 2px rgba(74, 74, 74, 0.25);
  margin-bottom: 6px;
  padding: 10px 13.33333333px;
}
.map-search_sectors .sector.selected .badge-number {
  top: 6px;
  background: #67D699;
}
.map-search_sectors .sector:not(.selected) {
  opacity: .4;
  align-items: center;
}
.map-search_sectors .sector:not(.selected) + .sector:not(.selected) {
  border-top: 1px solid rgba(150, 173, 135, 0.6);
}
.map-search_sectors .sector:not(.selected) p {
  display: none;
}
.map-search_sectors .sector:not(.selected) .resources {
  max-height: 0;
  padding-top: 0;
  border-top: 0;
  margin-top: 0;
}
.map-search_sectors .sector:not(.selected) .image {
  width: 15%;
}
.map-search_sectors .sector:not(.selected):hover {
  opacity: 1;
}
.map-search_results {
  display: flex;
  flex-direction: column;
  width: 80vw;
}
.map-search_results .top-bar {
  margin: 6px 0;
  display: flex;
  min-height: 40px;
}
.map-search_results .select-location {
  background: #f7f8f9;
  padding: .5rem .5rem .2rem;
  width: 70%;
  align-items: flex-start;
}
.map-search_results .select-location .filter-label {
  font-size: 16px;
  letter-spacing: .02em;
  color: #114A29;
  padding-top: .4rem;
  font-weight: 700;
}
.map-search_results .select-location:hover {
  background: #eef0f2;
}
.map-search_results .search-form {
  padding: 0;
  border: 0;
  margin: 0;
  width: 30%;
}
.map-search_results .search-form .search-input {
  background: #f7f8f9;
  margin: 0 6px;
  height: 40px;
}
.map-search_results .search-form .search-input input {
  background: rgba(103, 214, 153, 0.2);
  border-radius: 0;
  box-shadow: none;
}
.map-search_results .search-form .search-input input[value=""] {
  background: transparent;
}
.map-search_results .search-form .search-input:hover {
  background: #eef0f2;
}
.map-search_results .results {
  position: relative;
  display: flex;
  box-shadow: none;
  outline: none;
}
.map-search_results .map-container {
  position: relative;
  width: 100%;
  z-index: 0;
  transition: width 0.4s;
}
.datasets-list {
  list-style: none;
  margin: 0;
  padding: 3vw;
  position: relative;
}
.datasets-list .list-header {
  display: flex;
  width: 100%;
  align-items: baseline;
}
.datasets-list .list-header-dataset {
  flex: 2;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.datasets-list .list-header-resources {
  flex: 1;
  align-items: baseline;
}
.datasets-list .list-title {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: .02em;
  margin: 0;
  padding: .2em 0;
}
.datasets-list .dataset {
  display: flex;
  width: 100%;
}
.datasets-list .dataset .dataset-info {
  flex: 2;
  border-top: 1px solid rgba(150, 173, 135, 0.3);
  margin-right: 20px;
  padding: 1rem 0;
}
.datasets-list .dataset .resources {
  flex: 1;
  border-top: 1px solid rgba(150, 173, 135, 0.3);
  list-style: none;
  margin: 0;
  padding: 1rem 0;
}
.datasets-list .dataset .resources li {
  padding: .1em 0;
}
.datasets-list .dataset .title {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: .02em;
  color: #4A4A4A;
  display: inline-block;
  margin-bottom: .6rem;
}
.datasets-list .loading-container {
  border-top: 1px solid rgba(150, 173, 135, 0.3);
}
.no-results {
  border-top: 1px solid rgba(150, 173, 135, 0.3);
  width: 100%;
  height: 50vh;
  padding-top: 20vh;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3em;
  color: #b0b0b0;
  background: linear-gradient(#f7f8f9, #fff);
}
.no-results .em {
  color: #1D8449;
}
.datasets-drawer {
  background: #f7f8f9;
  box-shadow: 1px 0px 2px rgba(74, 74, 74, 0.3) inset;
  width: 6px;
  z-index: 3;
  position: relative;
  transition: width 0.4s;
}
.datasets-drawer .download-section {
  display: none;
}
.drawer-toggle {
  position: absolute;
  left: -30px;
  width: 32px;
  height: 60px;
  padding: 1em .6em;
  box-shadow: 1px 1px 1px rgba(74, 74, 74, 0.3) inset;
  border-radius: 2px 0 0 2px;
  top: 40%;
  z-index: 3;
  background: #f7f8f9;
  font-size: 20px;
  color: #67D699;
}
.datasets-list-small {
  position: absolute;
  top: 0;
  bottom: 30px;
  width: 100%;
  padding: 6px;
  overflow-y: auto;
  display: none;
}
.datasets-list-small .list-title {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: .02em;
  margin: 0;
  padding: 13.33333333px;
  padding-bottom: 0;
}
.datasets-list-small ul {
  padding: 13.33333333px;
  margin: 0;
  list-style: none;
}
.datasets-list-small .dataset {
  border-top: 1px solid rgba(150, 173, 135, 0.3);
  padding: .4em 0;
}
.datasets-list-small .resources {
  padding: .2em 0;
}
.datasets-list-small .resources li {
  padding: .1em 0 .1em 1em;
}
.datasets-list-small .dataset.collapsed .resources,
.datasets-list-small .dataset.collapsed i {
  display: none;
}
.datasets-list-small .dataset.selected .title {
  font-weight: 700;
  color: #4A4A4A;
}
.datasets-list-small .title {
  color: #b0b0b0;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.datasets-list-small .title i {
  color: #67D699;
  float: right;
  transform: rotate(45deg);
}
.datasets-list-small .no-results {
  padding-top: 10vh;
  background: none;
}
.datasets-list-small .loading-container {
  border-top: 1px solid rgba(150, 173, 135, 0.3);
}
.download-section {
  position: absolute;
  margin: 0 6px;
  left: 0;
  right: 0;
  bottom: 1px;
  top: 100%;
  min-width: 200px;
  background: #f7f8f9;
  border-radius: 2px 2px 0 0;
  box-shadow: 1px 0 1px rgba(74, 74, 74, 0.3);
  display: flex;
  flex-direction: column;
  transition: top .4s;
}
.download-section:not(.collapsed) {
  top: 46px;
}
.download-section:not(.collapsed) .download-toggle .download-toggle-icon {
  border: 0;
  border-bottom: 3px solid #fff;
}
.download-section.collapsed .download-button {
  display: none;
}
.download-section.collapsed .download-settings {
  margin: 0;
}
.download-section .download-toggle {
  border-radius: 2px 2px 0 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -40px;
  height: 40px;
  padding-left: 13.33333333px;
  padding-right: 13.33333333px;
}
.download-section .download-toggle .download-toggle-icon {
  width: 1rem;
  height: 1rem;
  border-top: 3px solid #fff;
}
.download-section .download-settings {
  overflow-y: auto;
  margin-bottom: 40px;
}
.download-section .download-settings .settings-section {
  list-style: none;
  margin: 0 13.33333333px;
  padding: 6.66666667px 0;
  border-bottom: 1px solid rgba(150, 173, 135, 0.3);
}
.download-section .download-settings .settings-section li {
  padding: .1em 0;
  list-style: none;
}
.download-section .download-settings .settings-section ul {
  margin: 0.3em 0 0 20px;
}
.download-section .download-summary {
  padding: 13.33333333px;
}
.download-section .download-summary .title {
  font-size: 16px;
  margin: 0;
}
.download-section .download-summary ul {
  list-style: none;
  font-size: 0.9rem;
  margin: 0;
  padding: .4rem 0;
}
.download-section .download-summary li {
  padding: .2em 0;
  vertical-align: baseline;
}
.download-section .download-button {
  margin-top: auto;
  position: absolute;
  bottom: 0;
}
.show-datasets .map-container {
  width: 70%;
}
.show-datasets .datasets-drawer {
  width: 30%;
}
.show-datasets .datasets-drawer .download-section {
  display: flex;
}
.show-datasets .datasets-list-small {
  display: block;
}
.list-view.map-search {
  height: auto;
  min-height: 100vh;
}
.list-view .results .list-container {
  width: 100%;
  overflow-y: auto;
}
.list-view .map-container,
.list-view .datasets-drawer {
  display: none;
}
.list-view .download-section {
  position: fixed;
  left: 70%;
  box-shadow: 0px -1px 6px rgba(74, 74, 74, 0.3), 0px -1px 1px rgba(74, 74, 74, 0.2);
}
.list-view .download-section .download-toggle {
  box-shadow: 0px -1px 6px rgba(74, 74, 74, 0.3), 0px -1px 1px rgba(74, 74, 74, 0.2);
}
.list-view .download-section:not(.collapsed) {
  top: calc(100vh - 400px);
}
.map-view .results {
  flex: 1 1 auto;
}
.map-view .list-container {
  display: none;
}
.map-view .map-div {
  height: 100%;
}
.map-search-content + .site-footer {
  display: none;
}
@media (max-width: 980px) {
  .show-datasets .map-container {
    width: 50%;
  }
  .show-datasets .datasets-drawer {
    width: 50%;
  }
  .show-datasets .datasets-list-small {
    display: block;
  }
}
@media (max-width: 767px) {
  .map-search-content {
    margin: 0 -20px;
  }
  .show-datasets .map-container {
    width: 20%;
  }
  .show-datasets .datasets-drawer {
    width: 80%;
  }
  .show-datasets .datasets-list-small {
    display: block;
  }
}
.home-nav {
  position: absolute;
  top: 0;
  width: 100%;
  margin-bottom: 10vh;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: min-height 0.6s;
  z-index: 10000;
}
.home-nav .account-masthead,
.home-nav .masthead {
  background: none;
  box-shadow: none;
  margin-bottom: 0;
  z-index: 10000;
}
.home-nav .navigation .nav-pills li a {
  font-size: 1.4rem;
}
.home-nav .navigation .nav-pills li.active a:after {
  content: none;
}
.home-nav .bg-image {
  position: fixed;
  width: 100%;
  height: 90vh;
  transition: height .6s;
}
.home-nav .header-image {
  display: none;
}
.home-nav .navbar {
  transition: all .6s;
  position: absolute;
  top: calc(90vh - 8rem);
  width: 100%;
}
.home-nav + div {
  margin-top: 100vh;
  transition: margin .6s;
}
.header-banner {
  margin: 0 auto;
  text-align: center;
  color: #fff;
  padding-top: 15vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: top .6s;
  z-index: 9000;
}
.header-banner .government-logo {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 100px;
}
.header-banner .grid-nigeria-logo {
  width: 100%;
  max-width: 350px;
  margin: 0;
}
.header-banner .banner-title {
  font-family: 'Raleway', sane-serif;
  font-size: 2.9rem;
  font-weight: 300;
  line-height: 1.3em;
  margin-bottom: 20px;
  font-weight: 700;
  margin: 6vh 20%;
}
.header-search {
  position: relative;
  margin-left: 20px;
  width: 40%;
  transition: margin .4s;
}
.header-search input {
  background: rgba(17, 74, 41, 0.5);
  border: 0;
  font-size: 2rem;
  color: #fff;
  height: auto;
  width: 100%;
  padding: .9em 1em .9em 2.5em;
  border-radius: 2px;
  transition: padding .6s;
  margin: 0;
}
.header-search ::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.header-search i {
  position: absolute;
  left: 20px;
  top: .9em;
  font-size: 2rem;
}
.home-nav.collapsed {
  min-height: 5rem;
}
.home-nav.collapsed .header-banner {
  top: -90vh;
}
.home-nav.collapsed .bg-image {
  height: 6.5rem;
}
.home-nav.collapsed .account-masthead {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background: #219552;
}
.home-nav.collapsed .navbar {
  position: fixed;
  top: 2rem;
  width: 100%;
  height: 4.5rem;
  background: rgba(29, 132, 73, 0.8);
  box-shadow: 0 1px 2px rgba(74, 74, 74, 0.5);
}
.home-nav.collapsed .btn-navbar {
  padding: 10px;
}
.home-nav.collapsed .header-search form {
  margin: 0;
}
.home-nav.collapsed .header-search input {
  padding: .6em 1em .6em 2.5em;
  font-size: 1.4rem;
}
.home-nav.collapsed .header-search i {
  top: .7em;
  font-size: 1.4rem;
}
.home-nav.collapsed + div {
  margin-top: 15rem;
}
.home-nav.collapsed .scroll-down {
  display: none;
}
.scroll-down {
  position: absolute;
  left: calc(50% - 2.5rem);
  top: 89vh;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  transition: all .6s;
  cursor: pointer;
}
.scroll-down::before {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  border-bottom: 3px solid #67D699;
  border-right: 3px solid #67D699;
  transform: rotate(45deg);
  margin-bottom: .8rem;
  transition: width .6s, height .6s;
}
.scroll-down:hover {
  background: #67D699;
}
.scroll-down:hover::before {
  border-color: #fff;
}
.teaser {
  margin-bottom: 20px;
}
.teaser .teaser-heading {
  border-top: 1px solid #96AD87;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.teaser .teaser-heading:hover {
  text-decoration: none;
}
.teaser .teaser-heading:hover .title-xl {
  color: #67D699;
}
.teaser .teaser-heading:hover .icon-linkto {
  box-shadow: 0 0 0 0.3em #67D699;
}
.teaser .title-xl {
  color: #4A4A4A;
}
.teaser p {
  font-size: 16px;
}
.teaser .icon-linkto {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #67D699;
  position: relative;
  transition: box-shadow 0.2s;
  margin-right: 10px;
  flex: 0 0 auto;
}
.teaser .icon-linkto::before {
  content: "";
  display: block;
  position: absolute;
  width: 22%;
  height: 22%;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  top: 30%;
  right: 30%;
}
.teaser .icon-linkto::after {
  content: "";
  display: block;
  position: absolute;
  height: 50%;
  width: 10%;
  width: 2px;
  background: #fff;
  top: 30%;
  right: 29%;
  transform: rotate(45deg);
  transform-origin: top left;
}
.support-teaser {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8% 35% 5%;
  color: #fff;
}
.widget-headline {
  font-family: 'Raleway', sane-serif;
  font-size: 2.9rem;
  font-weight: 300;
  line-height: 1.3em;
  margin-bottom: 20px;
}
.widget-headline b {
  font-weight: 400;
}
.widget-headline .badge-number {
  font-size: 2rem;
  border-radius: 1em;
  line-height: 1.1em;
  height: auto;
  background: #67D699;
}
.map-sector-widget {
  display: flex;
  width: 100%;
  padding: 20px 0;
}
.home-map {
  min-height: 360px;
  flex: .8;
  position: relative;
}
.home-map .leaflet-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: none;
}
.home-map .leaflet-tooltip {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  padding: 10px 20px;
  font-family: 'Roboto Condensed', sans-serif;
  transition: 150ms opacity ease-in-out 20ms;
}
.home-sectors {
  flex: 1.2;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.home-sector-item {
  width: 46%;
  padding: 0;
  margin-left: 4%;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(150, 173, 135, 0.3);
}
.home-sector-item .badge-number {
  font-size: 16px;
  border-radius: 1em;
  line-height: 1.2em;
  background: #67D699;
}
.home-sector-item .image {
  width: 70px;
  padding: 0 5px;
}
.home-sector-item:hover {
  cursor: pointer;
  color: #54C286;
}
.home-sector-item:hover .badge-number {
  box-shadow: 0 0 0 0.3em #67D699;
}
.home-sector-heading {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .02em;
  line-height: 1.3em;
}
.divider {
  width: 100%;
  margin: 20px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divider::before,
.divider::after {
  content: '';
  display: block;
  background: #67D699;
  flex: 1;
  height: 6px;
}
.divider .btn {
  margin: 0 20px;
  padding: .8em 2em;
}
@media (max-width: 980px) {
  .home-nav .nav-collapse.show {
    background: #1D8449;
    padding-left: 25px;
  }
  .header-search {
    width: 70vw;
    margin-left: 0;
  }
  .home-nav .btn-navbar {
    padding: 15px;
    margin-top: 0;
  }
  .home-nav .btn-navbar:focus,
  .home-nav .btn-navbar:hover {
    margin-top: 0;
  }
  .home-nav .btn-navbar .icon-bar {
    width: 22px;
  }
  .home-nav .btn-navbar .icon-bar + .icon-bar {
    margin-top: 6px;
  }
  .home-nav.collapsed .navbar {
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .support-teaser {
    padding: 8% 20% 5%;
  }
  .home-nav {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
  }
  .header-banner .banner-title {
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: .02em;
    margin-bottom: 20px;
    font-size: 2rem;
    margin: 6vh 10%;
  }
  .header-banner .government-logo {
    width: 70px;
  }
  .header-banner .grid-nigeria-logo {
    width: 70%;
    max-width: 300px;
  }
  .map-sector-widget {
    flex-direction: column;
  }
  .home-sector-item {
    margin: 0 2.5%;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .header-banner {
    margin: 0 auto;
  }
  .home-nav .navbar {
    top: calc(90vh - 6rem);
  }
  .home-nav .btn-navbar {
    padding: 10px;
  }
  .header-search input {
    padding: .6em 1em .6em 2.5em;
    font-size: 1.4rem;
  }
  .header-search i {
    top: .7em;
    font-size: 1.4rem;
  }
}
.page-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.page-container .page-title {
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: .02em;
  margin-bottom: 20px;
}
.page-container .left {
  flex: 1;
  margin-right: 60px;
}
.page-container .right {
  flex: 3;
}
.page-container .left .box {
  padding: 5px 10px;
  display: flex;
}
.page-container .left .box .image {
  flex: 1;
  height: auto;
  padding-right: .4em;
  position: relative;
  left: -4px;
  mix-blend-mode: multiply;
}
.page-container .left .box img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.page-container .left .box .info {
  flex: 3;
}
.page-container .module-content {
  padding: 10px 0;
  margin: 0;
}
@media (max-width: 767px) {
  .page-container {
    display: block;
  }
  .page-container .left,
  .page-container .right,
  .page-container > .page-title {
    margin: 20px;
  }
  .page-container .page-title {
    width: auto;
  }
  .page-container .nav-tabs,
  .page-container .tab-content {
    margin: 0 -20px;
  }
}
.image-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10% 3% 3%;
  margin: 0 -20px;
  color: #fff;
}
.bg-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  background-color: #f7f8f9;
}
.bg-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image.cover {
  position: relative;
  min-height: 180px;
}
.image.cover img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image.center {
  position: relative;
  text-align: center;
}
.image.center img {
  width: 60%;
  height: 60%;
  max-height: 200px;
  object-fit: contain;
}
.module-content.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.module-content.flex > div {
  flex: 1;
  margin: 20px 3%;
}
.module-content.flex > div:first-child {
  margin-left: 0;
}
.module-content.flex > div:last-child {
  margin-right: 0;
}
.module-content.flex > .flex2 {
  flex: 2;
}
.grid3-header {
  color: #4A4A4A;
}
.grid-nigeria-logo {
  max-width: 300px;
  margin-bottom: 3%;
}
.grid-logo {
  max-width: 300px;
  margin-bottom: 2%;
}
.partner {
  border-top: 1px solid #96AD87;
  margin-bottom: 40px;
  display: flex;
}
.partner img {
  margin: 2% auto;
  width: 70%;
  object-fit: contain;
}
.grid3-countries {
  position: relative;
  display: flex;
  padding: 3% 10%;
  margin: 0 -20px;
}
.grid3-countries img {
  width: 50%;
  max-height: 400px;
  object-fit: contain;
}
.grid3-countries ul li {
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: .02em;
  padding-top: 10px;
  color: #96AD87;
}
.support-headline {
  font-family: 'Raleway', sane-serif;
  font-size: 2.9rem;
  font-weight: 300;
  line-height: 1.3em;
  margin-bottom: 20px;
  position: relative;
}
.support-headline i {
  position: absolute;
  top: -0.5em;
}
.support-number {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: .02em;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 2.9rem;
  background: rgba(29, 132, 73, 0.3);
  padding: 0 .4em;
  border-radius: .2em;
}
.support-number i {
  font-size: 2rem;
  margin-right: .4em;
}
.support-info {
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: .02em;
}
.support-cases .teaser-heading {
  color: #4A4A4A;
}
.support-cases .support-case {
  padding-left: 2rem;
  position: relative;
}
.support-cases .support-case-title {
  font-size: 16px;
  display: inline-block;
  color: #4A4A4A;
  padding: .2em 0;
}
.support-cases .support-case-title i {
  color: #67D699;
  font-size: 1.4rem;
  position: absolute;
  left: 0;
  top: 0;
}
.support-cases .support-case-date {
  font-size: 0.9rem;
  color: #b0b0b0;
}
.faq .module-content {
  column-count: 2;
  column-gap: 5%;
}
.faq .module-content > div {
  padding: 20px 0;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media (max-width: 767px) {
  .module-content.flex {
    flex-direction: column;
  }
  .module-content.flex > div {
    margin: 20px 0;
  }
  .faq .module-content {
    column-count: 1;
  }
}
@media (max-width: 767px) {
  .image-header,
  .grid3-countries {
    margin: 0;
  }
}
.state-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.state-container .page-title {
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: .02em;
  margin-bottom: 20px;
}
.state-container .left {
  flex: 1;
  margin-right: 60px;
}
.state-container .right {
  flex: 3;
}
.state-container .left .box {
  padding: 5px 10px;
  display: flex;
}
.state-container .left .box .image {
  flex: 1;
  height: auto;
  padding-right: .4em;
  position: relative;
  left: -4px;
  mix-blend-mode: multiply;
}
.state-container .left .box img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.state-container .left .box .info {
  flex: 3;
}
.state-container .module-content {
  padding: 10px 0;
  margin: 0;
}
@media (max-width: 767px) {
  .state-container {
    display: block;
  }
  .state-container .left,
  .state-container .right,
  .state-container > .page-title {
    margin: 20px;
  }
  .state-container .page-title {
    width: auto;
  }
  .state-container .nav-tabs,
  .state-container .tab-content {
    margin: 0 -20px;
  }
}
.state-container .page-preload {
  display: block;
  height: 400px;
}
.state-container .module-content .entry {
  font-weight: bold;
  font-size: 1.2em;
}
.state-container .module-content .number {
  font-size: 1.5em;
}
.state-container .datasets-list {
  background: white;
  padding: 0;
}
.state-container .datasets-list .dataset-info {
  margin: 0;
}
.state-container .datasets-summary {
  display: flex;
  align-items: baseline;
  padding: 40px 20px;
}
.state-container .datasets-summary .btn {
  margin-left: auto;
}
.state-container .datasets-summary .badge-number {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: .02em;
  margin-bottom: 20px;
  border-radius: 1em;
  margin-right: .4em;
  background: #67D699;
}
.state-container .datasets-actions {
  display: flex;
  margin-left: auto;
}
.state-container .datasets-actions .action-item {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .state-container .datasets-list .dataset-info {
    padding-left: 20px;
  }
}
.states-overview .search-form {
  padding: 0;
  border: 0;
  margin: 0;
}
.states-overview .search-form .search-input {
  background: #f7f8f9;
}
.states-overview .search-form .search-input input {
  width: 100%;
  border: 0;
  box-shadow: none;
  font-size: 16px;
}
.states-overview .search-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.states-overview .search-heading .heading-xl {
  margin-bottom: 10px;
}
.dataset-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.dataset-container .page-title {
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: .02em;
  margin-bottom: 20px;
}
.dataset-container .left {
  flex: 1;
  margin-right: 60px;
}
.dataset-container .right {
  flex: 3;
}
.dataset-container .left .box {
  padding: 5px 10px;
  display: flex;
}
.dataset-container .left .box .image {
  flex: 1;
  height: auto;
  padding-right: .4em;
  position: relative;
  left: -4px;
  mix-blend-mode: multiply;
}
.dataset-container .left .box img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.dataset-container .left .box .info {
  flex: 3;
}
.dataset-container .module-content {
  padding: 10px 0;
  margin: 0;
}
@media (max-width: 767px) {
  .dataset-container {
    display: block;
  }
  .dataset-container .left,
  .dataset-container .right,
  .dataset-container > .page-title {
    margin: 20px;
  }
  .dataset-container .page-title {
    width: auto;
  }
  .dataset-container .nav-tabs,
  .dataset-container .tab-content {
    margin: 0 -20px;
  }
}
.dataset-container .module-content .entry {
  font-weight: bold;
  font-size: 1.2em;
}
.dataset-container .module-content .number {
  font-size: 1.5em;
}
.dataset-container .resources {
  margin: 20px 0 20px;
}
.dataset-container .resource-list {
  background: #fff;
  margin: 0;
}
.dataset-container .resource-item {
  padding: 20px;
  border-top: 1px solid #e3e3e3;
  display: flex;
}
.dataset-container .resource-item:hover {
  background: none;
}
.dataset-container .resource-item .info {
  margin-left: 5px;
  margin-top: .1rem;
}
.dataset-container .resource-item .info a {
  display: flex;
  color: #4A4A4A;
  margin-bottom: .5rem;
  align-items: center;
}
.dataset-container .resource-actions {
  margin-left: auto;
}
.dataset-container .resource-main-actions {
  padding: 0 20px 20px;
  display: flex;
}
.dataset-container .resource-main-actions a {
  margin-left: auto;
}
.dataset-container .resource-main-actions.empty {
  padding: 30px 20px;
}
.dataset-container .resource-list-actions {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dataset-container .map-container {
  margin-top: 1px;
}
.dataset-container .map {
  width: 100%;
  min-height: 400px;
}
.metadata .title-l {
  margin: 40px 20px 10px;
}
.metadata-list {
  background: #fff;
  list-style: none;
  margin: 0;
}
.metadata-list li {
  border-top: 1px solid #e3e3e3;
  display: grid;
  padding: 6.66666667px 20px;
  grid-template-columns: 30% 70%;
}
.metadata-list label,
.metadata-list p {
  margin: 0;
}
.metadata-list label {
  font-weight: 700;
}
.metadata-list label::after {
  content: none;
}
.metadata-list label.custom {
  font-weight: 300;
}
.resource-container .page-title {
  margin-left: 20px;
}
.resource-container .module-content.flex {
  align-items: center;
}
.resource-container .module-content.flex .resource-actions {
  margin: 0;
}
.resource-container .resource-actions {
  display: flex;
  justify-content: flex-end;
}
.resource-container .resource-actions .btn {
  margin-left: 20px;
}
.resource-container .map-container {
  margin-top: 1px;
}
.default-height {
  min-height: 400px;
}
