.notification {
  &.unread {
    background: @color-neutral-pale;
  }
}

.notification-content {
  margin-bottom: @grid-gutter-width;
}

.notification {
  .notification-title,
  &.unread {
    .notification-icon {
      color: @color-primary;
    }
  }
}

.notification-icon {
  margin-right: @grid-gutter-width/2;
}

.redux-toastr {
  background: lighten(@color-primary, 4%);
  display: block;
}

.redux-toastr .top-center {
  min-height: 70px;
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0;
  left: 0;
  top: 0;
  position: relative;

  transition: min-height .3s, padding .3s;

  &:empty {
    min-height: 0;
    padding: 0 10px;
  }

  .toastr {
    margin: 0 0 4px;
    border-radius: 2px;
    min-height: 60px;
  }

  .toastr .rrt-middle-container {
    font-family: @base-font-family;
  }
}
