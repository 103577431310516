.resource-container {
  .page-title {
    margin-left: @grid-gutter-width;
  }

  .module-content.flex {
    align-items: center;

    .resource-actions {
      margin: 0;
    }
  }

  .resource-actions {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: @grid-gutter-width;
    }
  }
  .map-container {
    margin-top: 1px;
  }
}
.default-height { 
  min-height: 400px;
}
